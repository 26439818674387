import useSWR from "swr";
import Api from "../../services/Api";

function useGetFeaturedProducts() {
  const getKey = () => {
    return `${process.env.NETLIFY_API_BASE_ADURL}/product-listing?type=featured`;
  };

  const { data, error } = useSWR(() => getKey(), getFeaturedProducts);

  const isLoading = !data && !error;
  const featuredProducts =
    (data
      ? data?.["featured"]?.map((item) => {
          const {
            id,
            image,
            price,
            name,
            retail_price,
            sale_price,
            custom_url,
            brand_name,
          } = item;
          return {
            id,
            bigcommerce_id: id,
            image,
            price: price,
            name,
            retail_price: retail_price,
            sale_price: sale_price,
            custom_url,
            brand_name,
          };
        })
      : []) || [];

  return {
    featuredProducts,
    isLoading,
  };
}

async function getFeaturedProducts(url) {
  const response = await Api.get(url);
  return response.data.data;
}

export default useGetFeaturedProducts;
