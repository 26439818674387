import useSWR from "swr";
import Api from "../../services/Api";

function useGetNewProducts() {
  const getKey = () => {
    return `${process.env.NETLIFY_API_BASE_ADURL}/product-listing?type=new`;
  };

  const { data, error } = useSWR(() => getKey(), getNewProducts);

  const isLoading = !data && !error;
  const newProducts =
    (data
      ? data?.["new"]?.map((item) => {
          const {
            id,
            image,
            price,
            name,
            retail_price,
            sale_price,
            custom_url,
            brand_name
          } = item;
          return {
            id,
            bigcommerce_id: id,
            image,
            price: price,
            name,
            retail_price: retail_price,
            sale_price: sale_price,
            custom_url,
            brand_name
          };
        })
      : []) || [];

  return {
    newProducts,
    isLoading,
  };
}

async function getNewProducts(url) {
  const response = await Api.get(url);
  return response.data.data;
}

export default useGetNewProducts;
