import React from "react";

// slick slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Button from "../../components/form/button/Button";
import { carousel_slider_settings } from "../../settings/slider";
import { StaticImage } from "gatsby-plugin-image";

function HeroCarousel() {
  return (
    <div className="hero-carousel-section">
      <Slider {...carousel_slider_settings} className={"slider-wrapper"}>
        <div className="hero-carousel-item">
          <div className="hero-crousel-image">
            <StaticImage
              src={"../../img/banner-images/heroCarousel_1.jpg"}
              alt="carousel slider"
              placeholder="blurred"
            />
          </div>

          {/* <div className="heroCarousel-content">
            <span className="heroCarousel-title">Men's Clothing</span>
            <p className="heroCarousel-description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </p>
            <Button variant="primary">Shop Now</Button>
          </div> */}
        </div>

        <div className="hero-carousel-item">
          <div className="hero-crousel-image">
            <StaticImage
              src={"../../img/banner-images/heroCarousel_2.jpg"}
              alt="slider 02"
              placeholder="blurred"
            />
          </div>

          {/* <div className="heroCarousel-content">
            <span className="heroCarousel-title">Women's Clothing</span>
            <p className="heroCarousel-description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </p>
            <Button variant="primary">Shop Now</Button>
          </div> */}
        </div>

        <div className="hero-carousel-item">
          <div className="hero-crousel-image">
            <StaticImage
              src={"../../img/banner-images/heroCarousel_3.jpg"}
              alt="slider 02"
              placeholder="blurred"
            />
          </div>

          {/* <div className="heroCarousel-content">
            <span className="heroCarousel-title">Women's Clothing</span>
            <p className="heroCarousel-description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </p>
            <Button variant="primary">Shop Now</Button>
          </div> */}
        </div>
      </Slider>
    </div>
  );
}

export default HeroCarousel;
