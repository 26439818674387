import React from "react";
import HeroCarousel from "../sections/home-page/HomeCarouselSlider";
// import CategoryBanners from "../sections/home-page/CategoryBanners";
import Layout from "../layout/Layout";
// import PageBanner from "../components/banner/PageBanner";
import useGetFeaturedProducts from "../hooks/products/useGetFeaturedProducts";
import useGetNewProducts from "../hooks/products/useGetNewProducts";
import useGetPopularProducts from "../hooks/products/useGetPopularProducts";
import ProductsList from "../sections/product/Products-list";
import useGetAllBanners from "../hooks/banners/useGetAllBanners";
import { StaticImage } from "gatsby-plugin-image";

const IndexPageTemplate = () => {
  const { featuredProducts } = useGetFeaturedProducts();
  const { newProducts } = useGetNewProducts();
  const { popularProducts } = useGetPopularProducts();
  const { top_banner, bottom_banner } = useGetAllBanners("home_page");

  return (
    <Layout>
      {/* herocarousel */}

      <div className="home-hero-banner-section page-block">
        <div className="container">
          <div className="row flex">
            <div className="col hero-banner-col">
              <HeroCarousel />
            </div>
            <div className="col four-banner-col">
              <div className="row flex flex-wrap">
                <div className="col banner-1">
                  <StaticImage
                    src={"../img/banner-images/banner-col-1.jpg"}
                    alt=""
                    placeholder="blurred"
                    width={300}
                    height={188}
                  />
                </div>
                <div className="col banner-2">
                  <StaticImage
                    src={"../img/banner-images/banner-col-2.jpg"}
                    alt=""
                    placeholder="blurred"
                    width={300}
                    height={188}
                  />
                </div>
                <div className="col banner-3">
                  <StaticImage
                    src={"../img/banner-images/banner-col-3.jpg"}
                    alt=""
                    placeholder="blurred"
                    width={300}
                    height={188}
                  />
                </div>
                <div className="col banner-4">
                  <StaticImage
                    src={"../img/banner-images/banner-col-4.jpg"}
                    alt=""
                    placeholder="blurred"
                    width={300}
                    height={188}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="banner">
        <div className="container">
          <span
            dangerouslySetInnerHTML={{
              __html: top_banner,
            }}
          />
        </div>
      </div>

      {/* category banners */}
      {/* <CategoryBanners /> */}

      {/* featured products */}
      <ProductsList
        title={"Featured Products"}
        subTitle={"Featured of the Month"}
        products={featuredProducts}
        disableBg={true}
      />

      {/* popular products */}
      <ProductsList
        title={"Most Popular products"}
        subTitle={"Popular products of the Month"}
        products={popularProducts}
        disableBg={true}
      />

      {/* <PageBanner /> */}

      {/* new products */}
      <ProductsList
        title={"New Products"}
        subTitle={"Hot release of the month"}
        products={newProducts}
        disableBg={true}
      />

      <div className="banner">
        <div className="container">
          <span
            dangerouslySetInnerHTML={{
              __html: bottom_banner,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export const Head = ({ pageContext }) => (
  <>
    <title>{pageContext.pageTitle}</title>
    <meta name="description" content={pageContext.metaDescription} />
  </>
);

export default IndexPageTemplate;
